"use client";
import { useState } from "react";
import {
  SimpleListConfigurable,
  List,
  TextField,
  ReferenceField,
  FunctionField,
  BooleanField,
  TopToolbar,
  FilterButton,
  SelectColumnsButton,
  DatagridConfigurable,
  WrapperField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import Typography from "@mui/material/Typography";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import moment from "moment";
import { Button, Chip, Tooltip, Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import "moment/locale/pt-br";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { PostFilterSidebar } from "./DateFilterSidebar";
import { postFilters } from "./TopBarFilters";
import { PostBulkActionButtons } from "./ModalBulkActions";
import Grid from "@mui/material/Grid";
import { determineUserReadableRole, truncateString } from "./UserUtils";
import { useLocation } from "react-router-dom";
import { CustomExportButton } from "./CustomExport";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { UserListSocioColumn } from "./UserListSocioColumn";
import { UserListDateColumn } from "./UserListDateColumn";

export const UserList = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const userRole = localStorage.getItem("roles");
  const location = useLocation();
  const isMobile = localStorage.getItem("isMobile") === "true";

  // if (isLoading) return null;
  // console.log("test data", data);

  // const setHasPhysicalCard = { hasPhysicalCard: true };
  // const [hasPhysicalCard, setHasPhysicalCard] = useState<any>({
  //   hasPhysicalCard: true,
  // });

  const ListActions = () => {
    return (
      <TopToolbar sx={{ alignItems: "center" }}>
        {!isMobile && <SelectColumnsButton />}

        <FilterButton />
        {/* <CreateButton /> */}
        {/* <ExportButton /> */}
        {(determineUserReadableRole(userRole) === "admin" ||
          determineUserReadableRole(userRole) === "comms") && (
          <CustomExportButton location={location} />
        )}
        {isMobile ? (
          <Button size="small" onClick={() => setShowSidebar(!showSidebar)}>
            {showSidebar ? <ToggleOnIcon /> : <ToggleOffIcon />}
          </Button>
        ) : (
          <Button
            size="small"
            startIcon={showSidebar ? <ToggleOnIcon /> : <ToggleOffIcon />}
            onClick={() => setShowSidebar(!showSidebar)}>
            Filtro de Datas
          </Button>
        )}
      </TopToolbar>
    );
  };

  return (
    <>
      <PageTopBreadcrumb
        title="Sócios"
        firstPath="/#/users/crud"
        actionType="create"
      />
      <Card
        sx={{
          padding: 2,
          marginBottom: 3,
          width: "100%",
        }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item sm={12} xs={12}>
              {/* {JSON.stringify({
                location,
                search: location.search,
                pathname: location.pathname,
              })} */}

              <List title=" " actions={<ListActions />} filters={postFilters}>
                {showSidebar &&
                (determineUserReadableRole(userRole) === "admin" ||
                  determineUserReadableRole(userRole) === "finance" ||
                  determineUserReadableRole(userRole) === "secretary" ||
                  determineUserReadableRole(userRole) === "comms") ? (
                  <PostFilterSidebar />
                ) : (
                  <></>
                )}
                {isMobile ? (
                  <SimpleListConfigurable
                    primaryText={(record) => record.fullName}
                    secondaryText={(record) =>
                      `${record.email ? record.email : ""}`
                    }
                    tertiaryText={(record) =>
                      `M.: ${record.registrationNumber}-${record.registrationNumberDigit}`
                    }
                  />
                ) : (
                  <>
                    {(determineUserReadableRole(userRole) === "admin" ||
                      determineUserReadableRole(userRole) === "finance") && (
                      <DatagridConfigurable
                        // rowClick="show"
                        // sx={{
                        //   maxWidth: "80%",
                        //   overflow: "scroll",
                        // }}
                        bulkActionButtons={
                          determineUserReadableRole(userRole) === "admin" ? (
                            <PostBulkActionButtons />
                          ) : (
                            false
                          )
                        }>
                        <WrapperField label="Sócio">
                          <UserListSocioColumn />
                        </WrapperField>

                        <ArrayField label="Formações" source="userGraduations">
                          <SingleFieldList linkType={false}>
                            {/* <ChipField source="graduation.name" size="small" /> */}
                            <FunctionField
                              label="Formações"
                              render={(record: any) => {
                                // console.log("RENDERREQ", record.graduation.name);
                                return (
                                  <>
                                    {record &&
                                      record.hasOwnProperty("graduation") &&
                                      record.graduation.hasOwnProperty(
                                        "name"
                                      ) && (
                                        <>
                                          <Tooltip
                                            placement="right"
                                            title={
                                              record.graduation.name +
                                              " - " +
                                              record.yearCompletion +
                                              " - " +
                                              record.educationalInstitution.name
                                            }>
                                            <Chip
                                              label={truncateString(
                                                record.graduation.name +
                                                  " - " +
                                                  record.yearCompletion,
                                                23
                                              )}
                                              color="default"
                                            />
                                          </Tooltip>
                                        </>
                                      )}
                                  </>
                                );
                              }}
                            />
                          </SingleFieldList>
                        </ArrayField>
                        <ArrayField label="DTEs" source="technicalDivisions">
                          <SingleFieldList linkType={false}>
                            <ChipField source="name" size="small" />
                          </SingleFieldList>
                        </ArrayField>
                        {/* <FunctionField
                      label="Matrícula"
                      render={(record: any) => {
                        return (
                          <>
                            {record.registrationNumber +
                              "-" +
                              record.registrationNumberDigit}
                          </>
                        );
                      }}
                      /> */}
                        <FunctionField
                          label="Matrícula"
                          sortBy="registrationNumber"
                          render={(record: any) => {
                            return (
                              <>
                                {record.registrationNumber +
                                  "-" +
                                  record.registrationNumberDigit}
                              </>
                            );
                          }}
                        />
                        {/* <TextField source="registrationNumber" label="Matrícula" /> */}
                        <UserListDateColumn />

                        <ReferenceField
                          source="userCategoryId"
                          reference="user-categories/crud"
                          label="Categoria">
                          {/* <TextField sourc
                      e="name" /> */}
                          <ChipField source="name" size="small" />
                        </ReferenceField>
                        {/* <FunctionField
                      label="Categoria"
                      render={(record: any) => {
                        return record.CatSocio === "9" ? "Remido" : "Aspirante";
                      }}
                      /> */}
                        <FunctionField
                          label="Situação"
                          render={(record: any) => {
                            switch (record.accountStatus) {
                              case "ACTIVE":
                                return <Chip label="Ativo" color="success" />;
                              case "CANCELED":
                                return <Chip label="Cancelado" color="error" />;
                              case "NO_CONTACT":
                                return (
                                  <Chip label="Sem contato" color="warning" />
                                );
                              case "PENDING":
                                return (
                                  <Chip label="Pendente" color="warning" />
                                );
                              case "LICENSED":
                                return (
                                  <Chip label="Licenciado" color="warning" />
                                );
                              case "DECEASED":
                                return <Chip label="Falecido" color="info" />;
                              default:
                                return (
                                  <Chip
                                    label="Desconhecido"
                                    color="secondary"
                                    variant="outlined"
                                  />
                                );
                            }
                          }}
                        />

                        <BooleanField
                          label="Adimplência"
                          source="userEnabled"
                        />
                        <TextField
                          label="Última Contribuição"
                          source="userLastContribution"
                        />
                        <WrapperField label="Ações">
                          <ActionsDropdown />
                        </WrapperField>
                      </DatagridConfigurable>
                    )}
                    {(determineUserReadableRole(userRole) === "comms" ||
                      determineUserReadableRole(userRole) === "secretary") && (
                      <DatagridConfigurable
                        bulkActionButtons={false}
                        rowClick={
                          determineUserReadableRole(userRole) === "comms"
                            ? ""
                            : "show"
                        }>
                        <WrapperField label="Sócio">
                          <UserListSocioColumn />
                        </WrapperField>
                        {determineUserReadableRole(userRole) ===
                          "secretary" && (
                          <FunctionField
                            label="Matrícula"
                            sortBy="registrationNumber"
                            render={(record: any) => {
                              return (
                                <>
                                  {record.registrationNumber +
                                    "-" +
                                    record.registrationNumberDigit}
                                </>
                              );
                            }}
                          />
                        )}

                        <UserListDateColumn />

                        <ReferenceField
                          source="userCategoryId"
                          reference="user-categories/crud"
                          label="Categoria">
                          {/* <TextField source="name" /> */}
                          <ChipField source="name" size="small" />
                        </ReferenceField>

                        <FunctionField
                          label="Situação"
                          render={(record: any) => {
                            switch (record.accountStatus) {
                              case "ACTIVE":
                                return <Chip label="Ativo" color="success" />;
                              case "CANCELED":
                                return <Chip label="Cancelado" color="error" />;
                              case "NO_CONTACT":
                                return (
                                  <Chip label="Sem contato" color="warning" />
                                );
                              case "PENDING":
                                return (
                                  <Chip label="Pendente" color="warning" />
                                );
                              case "LICENSED":
                                return (
                                  <Chip label="Licenciado" color="warning" />
                                );
                              case "DECEASED":
                                return <Chip label="Falecido" color="info" />;
                              default:
                                return (
                                  <Chip
                                    label="Desconhecido"
                                    color="secondary"
                                    variant="outlined"
                                  />
                                );
                            }
                          }}
                        />
                        {determineUserReadableRole(userRole) ===
                          "secretary" && (
                          <BooleanField
                            label="Adimplência"
                            source="userEnabled"
                          />
                        )}
                        {determineUserReadableRole(userRole) ===
                          "secretary" && (
                          <TextField
                            label="Última Contribuição"
                            source="userLastContribution"
                          />
                        )}
                      </DatagridConfigurable>
                    )}
                  </>
                )}
              </List>
              {location.search.includes("userEnabled") && (
                <Alert
                  icon={<WarningAmberIcon fontSize="inherit" />}
                  severity="info">
                  A lista de membros adimplentes/inadimplentes atualizam em
                  background a cada 3 minutos e armazenadas em cache, alterações
                  que afetem adimplência podem levar até 3 min para surtir
                  efeito na visualização desta lista de Sócios. (Este cache não
                  afeta exportação de membros, mediante a exportação os dados
                  são solicitados em tempo real.)
                </Alert>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
